<template>
  <div class="integral-page-view">
    <div class="main-cont">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mt20 mb20">
        <el-breadcrumb-item :to="{ path: '/pointsMall' }">{{
          lang_data == 1 ? "积分商城" : "Integral mall"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          lang_data == 1 ? "兑换记录" : "For record"
        }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="exchange-panel">
        <div class="integral-detail-tabs">
          <div
            class="tabitem"
            :class="{ checked: type == 0 }"
            @click="onTabClick(0)"
          >
            {{ lang_data == 1 ? "全部" : "All" }}
          </div>
          <div
            class="tabitem"
            :class="{ checked: type == 1 }"
            @click="onTabClick(1)"
          >
            {{ lang_data == 1 ? "待发货" : "Prepare" }}
          </div>
          <div
            class="tabitem"
            :class="{ checked: type == 2 }"
            @click="onTabClick(2)"
          >
            {{ lang_data == 1 ? "待收货" : "Receive" }}
          </div>
          <div
            class="tabitem"
            :class="{ checked: type == 3 }"
            @click="onTabClick(3)"
          >
            {{ lang_data == 1 ? "已完成" : "Complete" }}
          </div>
        </div>

        <div
          class="record-list"
          v-loading="loading"
          :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
        >
          <div
            class="record-item"
            v-for="item in dataList"
            :key="item.id"
            @click="
              $router.push({
                path: '/detailsExchange',
                query: {
                  record_id: item.id,
                },
              })
            "
          >
            <div class="top-row">
              <div class="exchange-status" :class="[getClass(item.status)]">
                {{ item.status.value }}
              </div>
              <div class="exchange-time">
                {{
                  $moment(item.add_time.key * 1000).format("YYYY-MM-DD hh:mm")
                }}
              </div>
            </div>
            <div class="content-row">
              <div class="good-avatar">
                <img
                  v-if="item.main_image_url"
                  :src="item.main_image_url"
                  alt=""
                />
              </div>
              <div class="goods-info ml20">
                <div>
                  <b style="font-size: 16px">{{ item.goods_name }}</b>
                  <span class="goods-count">
                    <span style="font-size: 14px">
                      x <b>{{ item.exchange_num }}</b></span
                    ></span
                  >
                </div>
                <div class="goods-price">
                  {{ lang_data == 1 ? "总计" : "A total of" }}：<span
                    class="num mr5"
                    ><b style="color: #409eff">{{
                      item.exchange_integral.key
                    }}</b></span
                  >{{ lang_data == 1 ? "积分" : "Integral" }}
                </div>
              </div>
              <div style="margin-left: auto" v-if="item.status.key != 1">
                <el-button
                  size="small"
                  type="primary"
                  round
                  @click.stop="confirmAccept(item)"
                  v-if="item.status.key == 2"
                  >{{
                    lang_data == 1 ? "确认收货" : "Confirm the goods"
                  }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <el-empty
          v-if="dataList.length < 1 && !loading"
          :description="lang_data == 1 ? '暂无记录' : 'Has been used'"
        ></el-empty>

        <div class="page" v-if="dataList.length > 0">
          <el-pagination
            background
            layout="total,prev, pager, next"
            @current-change="changePage"
            :page-size="page.pageSize"
            :current-page="page.currentPage"
            :total="page.totalCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      type: 0,
      dataList: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        totalPage: 0,
        totalCount: 0,
      },
      loading: true,
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    changePage(val) {
      this.getList(val);
    },
    getClass(status) {
      let arr = ["", "wait", "wait", "success"];
      return arr[status.key];
    },
    onTabClick(type) {
      this.type = type;
      this.getList(1);
    },
    getList(current_page) {
      this.loading = true;
      let params = {
        status: this.type,
        current_page: current_page ? current_page : this.currentPage,
        // page_size: 5
      };

      this.$axios
        .get("/ucenter/exchange_integral_goods/getList", { params })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.dataList = res.data.list;
            this.page.currentPage = res.data.current_page;
            this.page.totalPage = res.data.total_page;
            this.page.totalCount = res.data.total_count;
          }
          this.loading = false;
        });
    },
    confirmAccept(item) {
      this.$confirm(
        this.lang_data == 1
          ? "确认已收到货品?"
          : "Acknowledge receipt of goods?"
      )
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post("/ucenter/exchange_integral_goods/complete", params)
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>
<style lang="scss" scoped>
.integral-page-view {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  height: calc(100% - 70px);
  overflow: auto;
  .main-cont {
    width: 1300px;
    flex: 1;
    margin: 0 auto;
    padding-bottom: 30px;
    .exchange-panel {
      width: 1300px;
      background: #ffffff;
      box-shadow: 0 0 12px 0#e4e4e4;
      border: 1px solid #e4e4e4;
      padding: 10px 20px;
      border-radius: 8px;
      box-sizing: border-box;

      .integral-detail-tabs {
        box-sizing: border-box;
        width: 100%;
        border-bottom: 1px solid #e1e1e5;
        display: flex;
        align-items: center;
        padding: 0 12px;
        .tabitem {
          width: 117px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          &.checked {
            border-color: #009ae3;
            font-weight: bold;
          }
        }
      }
      .record-list {
        display: flex;
        flex-direction: column;
        min-height: 200px;
        .record-item {
          border-radius: 4px;
          border: 1px solid #e1e1e5;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          .top-row {
            padding: 0 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fafafa;
            height: 35px;
            line-height: 35px;
            .exchange-status {
              font-size: 15px;
              font-weight: bold;
              color: #409eff;
              &.wait {
                color: #409eff;
              }
              &.success {
                color: #333;
              }
            }
            .exchange-time {
              color: #b0b1b5;
            }
          }

          .content-row {
            padding: 12px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .good-avatar {
              width: 60px;
              height: 60px;
              background: #eee;
              img {
                width: 100%;
              }
            }
            .goods-info {
              height: 60px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
          }
        }
      }
      .page {
        margin: 20px auto 10px;
        text-align: right;
      }
    }
  }
}
</style>